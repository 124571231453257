class Hotspot {	
    constructor(id, name, pos, width, actionType, content, userData, scale) {	
        this.id = id;	
        this.name = name;	
        this.width = width;	
        this.pos = pos;	
        this.actionType = actionType;	
        this.content = content;	
        this.userData = userData;	
        this.scale = scale;	
    }	
}	
class HotspotManager {	
    constructor() {	
        this.OverLay = false;	
    }	
    Display(hotspotObject) {	
        switch (hotspotObject.actionType) {	
            case "Image":	
                this.DisplayImage(hotspotObject);	
                this.OverLay = true;	
                break;	
            case "Video":	
                this.DisplayVideo(hotspotObject);	
                this.OverLay = true;	
                break;	
            case "URL":	
                this.DisplayURL(hotspotObject);	
                break;	
            case "Animation":	
                this.DisplayAnimation(hotspotObject);	
                break;	
        }	
    }	
    Hide() {	
        var annotationDiv = document.getElementById('annotation-div');	
        if (annotationDiv) {	
            document.body.removeChild(annotationDiv);	
        }	
    }	
    ConstructAndDisplayOverlayElement(hotspotAction, hotspotObject) {	
        var annotationDiv = document.createElement("div");	
        annotationDiv.id = "annotation-div";	
        annotationDiv.setAttribute("style", "text-align: center; position: fixed; top: 0; left: 0; bottom: 0; right: 0; background-color: rgba(0, 0, 0, 0.4); z-index: 1000; box-shadow: 0px 0px 5px 1px white");	
        var div = document.createElement("div");	
        div.id = "hotspotActionDiv";	
        div.setAttribute("style", "position: relative; text-align: center; top: 50%; transform: translateY(-50%)");	
        let annotationDivParent = document.body;	
        if (cds.cadViewer.hotspotImgDisplayType === "viewer") {	
            var v = document.getElementsByClassName("cds-cad-viewer-container")[0];	
            if (v) annotationDivParent = v;	
            annotationDiv.style.position = "absolute";	
        }	
        if (hotspotObject.content.length > 1) {	
            div.appendChild(hotspotAction);	
            annotationDiv.appendChild(div);	
            var newDiv = this.constructAndGetMediaChanger(hotspotAction, hotspotObject);	
            annotationDiv.appendChild(newDiv);	
        }	
        else {	
            div.appendChild(hotspotAction);	
            annotationDiv.appendChild(div);	
        }	
        var isMobile = /Android/i.test(navigator.userAgent);	
        if (isMobile) {	
            console.log("Android device detected")	
            annotationDiv.ontouchstart = function (e) {	
                	
                if (hotspotAction == e.target) {	
                    	
                    hotspotAction.src = "";	
                    annotationDivParent.removeChild(annotationDiv);	
                    	
                }	
                e.preventDefault();	
            }	
        }else{	
            annotationDiv.onclick = function (e) {	
               	
                if (hotspotAction == e.target) {	
                    	
                    hotspotAction.src = "";	
                    annotationDivParent.removeChild(annotationDiv);	
                }	
                e.preventDefault();	
            }	
        }	
        	
        	
        annotationDivParent.appendChild(annotationDiv);	
    }	
    constructAndGetMediaChanger(hotspotAction, hotspotObject) {	
        var countDiv = document.createElement("div");	
        var preImg = document.createElement("img");	
        preImg.src = "https://www.product-config.net/cadviewer/images/icons/PreviousArrow.svg";	
        //preImg.width = "100";	
        //preImg.height = "80";	
        preImg.title = "Previous";	
        var isMobile = /iPhone|Android/i.test(navigator.userAgent);	
        if (isMobile) {	
            if (window.matchMedia("(orientation: portrait)").matches) { //Check Device is in Portrait or landscape mode	
                preImg.setAttribute("style", "position:absolute;left:10px;top:10%;width:15%;");    //top15%	
            }	
            else{	
                //preImg.setAttribute("style", "position:absolute;left:10px;top:40%;width:95px;height:95px;");	
                preImg.setAttribute("style", "position:absolute;left:1%;top:40%; width:5%");	
            }            	
            	
        }	
        else{	
            preImg.setAttribute("style", "position:absolute;left:1%;top:40%; width:5%");	
        }	
        	
        var para = document.createElement("p");	
        para.textContent = "1/" + `${hotspotObject.content.length}`;	
        var nodeDiv = document.createElement("div");	
        nodeDiv.appendChild(para);	
        countDiv.appendChild(nodeDiv);	
        var isMobile = /iPhone|Android/i.test(navigator.userAgent);	
        if (isMobile){
            preImg.ontouchstart = function() {
                for (var i = 0; i < hotspotObject.content.length; ++i) {	
                    if(cds.cadViewer.para && cds.cadViewer.boolIE){
                        para = cds.cadViewer.para;
                    }

                    var newPara = para.textContent.split("/");	
            
                    var paraToNum = parseInt(newPara[0]) - 1;	
                    if (hotspotAction.src == hotspotObject.content[paraToNum]) {	
                        var j = paraToNum - 1;	
                        if (j < 0) { paraToNum = hotspotObject.content.length; }	
                        if (hotspotObject.content[j] == undefined && hotspotAction.src == hotspotObject.content[hotspotObject.content.length - 1]) {	
                            hotspotAction.src = hotspotObject.content[hotspotObject.content.length - 1];	
                            para.textContent = `${hotspotObject.content.length}` + "/" + `${hotspotObject.content.length}`;	
                        }	
                        else {	
                            hotspotAction.src = hotspotObject.content[--paraToNum];	
                            para.textContent = `${paraToNum + 1}` + "/" + `${hotspotObject.content.length}`;	
                        }	
                        // To overcome the data loss of para var in IE
                        cds.cadViewer.para = para.cloneNode(true);
                        nodeDiv.appendChild(para);	
                        countDiv.innerHTML = "";	
                        countDiv.appendChild(nodeDiv);	
                        break;	
                    }	
                }
            }
        }else{
            preImg.onclick = function () {	
                for (var i = 0; i < hotspotObject.content.length; ++i) {	
                    if(cds.cadViewer.para && cds.cadViewer.boolIE){
                        para = cds.cadViewer.para;
                    }

                    var newPara = para.textContent.split("/");	

                    var paraToNum = parseInt(newPara[0]) - 1;	
                    if (hotspotAction.src == hotspotObject.content[paraToNum]) {	
                        var j = paraToNum - 1;	
                        if (j < 0) { paraToNum = hotspotObject.content.length; }	
                        if (hotspotObject.content[j] == undefined && hotspotAction.src == hotspotObject.content[hotspotObject.content.length - 1]) {	
                            hotspotAction.src = hotspotObject.content[hotspotObject.content.length - 1];	
                            para.textContent = `${hotspotObject.content.length}` + "/" + `${hotspotObject.content.length}`;	
                        }	
                        else {	
                            hotspotAction.src = hotspotObject.content[--paraToNum];	
                            para.textContent = `${paraToNum + 1}` + "/" + `${hotspotObject.content.length}`;	
                        }	
                        // To overcome the data loss of para var in IE
                        cds.cadViewer.para = para.cloneNode(true);
                        nodeDiv.appendChild(para);	
                        countDiv.innerHTML = "";	
                        countDiv.appendChild(nodeDiv);	
                        break;	
                    }	
                }	
            }
        }
    
        
        var nxtImg = document.createElement("img");	
        nxtImg.src = "https://www.product-config.net/cadviewer/images/icons/NextArrow.svg";	
        //nxtImg.width = "100";	
        //nxtImg.height = "80";	
        nxtImg.title = "Next";	
        var isMobile = /iPhone|Android/i.test(navigator.userAgent);	
        if (isMobile) {	
            if (window.matchMedia("(orientation: portrait)").matches) { //Check Device is in Portrait or landscape mode	
                nxtImg.setAttribute("style", "position:absolute;right:10px;top:10%;width:15%"); //Changed by Prashant  //top15%	
            }else{	
                //nxtImg.setAttribute("style", "position:absolute;right:10px;top:40%;width:95px;height:95px;"); //Changed by Prashant	
                nxtImg.setAttribute("style", "position:absolute;right:1%;top:40%;width:5%");	
            } 	
        }	
        else{	
            nxtImg.setAttribute("style", "position:absolute;right:1%;top:40%;width:5%");	
        }	
        
        var isMobile = /iPhone|Android/i.test(navigator.userAgent);	
        if (isMobile){
            nxtImg.ontouchstart = function(){
                for (var i = 0; i < hotspotObject.content.length; ++i) {	
                    if(cds.cadViewer.para && cds.cadViewer.boolIE){
                        para = cds.cadViewer.para;
                    }

                    var newPara = para.textContent.split("/");	
	
                    var paraToNum = parseInt(newPara[0]) - 1;	
                    if (hotspotAction.src == hotspotObject.content[paraToNum]) {	
                        var j = paraToNum + 1;	
                        if (hotspotObject.content[j] == undefined && hotspotAction.src == hotspotObject.content[hotspotObject.content.length - 1]) {	
                            hotspotAction.src = hotspotObject.content[0];	
                            para.textContent = 1 + "/" + `${hotspotObject.content.length}`;	
                        }	
                        else {	
                            hotspotAction.src = hotspotObject.content[++paraToNum];	
                            para.textContent = `${paraToNum + 1}` + "/" + `${hotspotObject.content.length}`;	
                        }	
                        // To overcome the data loss of para var in IE
                        cds.cadViewer.para = para.cloneNode(true);
                        nodeDiv.appendChild(para);	
                        countDiv.innerHTML = "";	
                        countDiv.appendChild(nodeDiv);	
                        break;	
                    }	
                }
            }
        }else{
            nxtImg.onclick = function () {	
                for (var i = 0; i < hotspotObject.content.length; ++i) {	
                    if(cds.cadViewer.para && cds.cadViewer.boolIE){
                        para = cds.cadViewer.para;
                    }

                    var newPara = para.textContent.split("/");	

                    var paraToNum = parseInt(newPara[0]) - 1;	
                    if (hotspotAction.src == hotspotObject.content[paraToNum]) {	
                        var j = paraToNum + 1;	
                        if (hotspotObject.content[j] == undefined && hotspotAction.src == hotspotObject.content[hotspotObject.content.length - 1]) {	
                            hotspotAction.src = hotspotObject.content[0];	
                            para.textContent = 1 + "/" + `${hotspotObject.content.length}`;	
                        }	
                        else {	
                            hotspotAction.src = hotspotObject.content[++paraToNum];	
                            para.textContent = `${paraToNum + 1}` + "/" + `${hotspotObject.content.length}`;	
                        }	
                        // To overcome the data loss of para var in IE
                        cds.cadViewer.para = para.cloneNode(true);
                        nodeDiv.appendChild(para);	
                        countDiv.innerHTML = "";	
                        countDiv.appendChild(nodeDiv);	
                        break;	
                    }	
                }	
            }	
        }
        
       

        var playerDiv = document.createElement("div");	
        playerDiv.appendChild(preImg);	
        playerDiv.appendChild(nxtImg);	
        return playerDiv;	
    }	
    DisplayImage(hotspotObject) {	
        var img = document.createElement("img");	
        img.src = hotspotObject.content[0];	
        //This code is added by Prashant for checking device	
        var isMobile = /iPhone|Android/i.test(navigator.userAgent);	
        if (isMobile) {	
            if (window.matchMedia("(orientation: portrait)").matches) { //Check Device is in Portrait or landscape mode	
                img.style.width = 100	
                }else{	
                    img.style.width = `${hotspotObject.width}%`;	
                }	
           	
        }else{	
            img.style.width = `${hotspotObject.width}%`;	
        }	
       	
        this.ConstructAndDisplayOverlayElement(img, hotspotObject);	
    }	
    DisplayVideo(hotspotObject) {	
        var video = document.createElement("video");	
        video.src = hotspotObject.content[0];	
        video.controls = true;	
        video.type = "video/mp4";	
        video.autoplay = true;	
        video.onclick = function(){	
            console.log("Video Close Click")	
        }	
        	
        	
        var isFirefox = typeof InstallTrigger !== 'undefined';  //Checking if browser is firefox then remove control	
        if (isFirefox) {	
            var isMobile = /iPhone|Android/i.test(navigator.userAgent);	
            if (isMobile) {	
                var androidMobile = /Android/i.test(navigator.userAgent);	
                if (androidMobile){	
                    function removeControls(video){	
                        video.removeAttribute('controls');	
                    }	
                    window.onload=removeControls(video);	
                }	
            }else{	
                function removeControls(video){	
                    video.removeAttribute('controls');	
                }	
           window.onload=removeControls(video);	
            }	
        }	
        	
          	
         //This code is added by Prashant for checking device	
        var isMobile = /iPhone|Android/i.test(navigator.userAgent);	
        if (isMobile) {	
            if (window.matchMedia("(orientation: portrait)").matches) { //Check Device is in Portrait or landscape mode	
                video.style.width = "90%"	
            }else{	
                video.style.width = `${hotspotObject.width}%`;	
            }	
        }else{	
            video.style.width = `${hotspotObject.width}%`;	
        }	
        video.controlsList = "nodownload";	
        video.disablePictureInPicture = true;	
        this.ConstructAndDisplayOverlayElement(video, hotspotObject);	
    }	
    DisplayURL(hotspotObject) {	
        window.open(hotspotObject.content);	
    }	
    DisplayAnimation(hotspotObject) {	
        cds.cadViewer.hasOwnProperty('studioUtils')	
            ? cds.cadViewer.studioUtils.play	
                ? cds.cadViewer.studioUtils.stopAnimation()	
                : cds.cadViewer.studioUtils.playAnimation() : false	
    }	
}	

export { HotspotManager };